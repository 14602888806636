/*
 * @Author: your name
 * @Date: 2021-12-07 10:48:30
 * @LastEditTime: 2021-12-28 16:54:22
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/components/chooseAdress/columns.js
 */
export default [
    {
        title: "商品标题",
        dataIndex: "zh_name",
        width: 160,
        key: "zh_name",
    },
    {
        title: "产品中文名",
        dataIndex: "zh_item_name",
        width: 160,
        key: "zh_item_name"
    },
    {
        title: "产品英文名",
        dataIndex: "en_item_name",
        width: 160,
        key: "en_item_name"
    },
    {
        title: "用途中文名",
        dataIndex: "zh_purpose",
        width: 160,
        key: "zh_purpose",
    },
    {
        title: "用途英文名",
        dataIndex: "en_purpose",
        width: 160,
        key: "en_purpose",
    },
    {
        title: "材质中文名",
        dataIndex: "zh_material",
        width: 130,
        key: "zh_material",
    },
    {
        title: "材质英文名",
        dataIndex: "en_material",
        width: 130,
        key: "en_material",
    },
    {
        title: "海关编码",
        dataIndex: "hs_code",
        width: 130,
        key: "hs_code",
    },
    {
        title: "产品品牌",
        dataIndex: "brand",
        width: 100,
        key: "brand",
    },
    {
        title: "产品型号",
        dataIndex: "model",
        width: 100,
        key: "model",
    },
    {
        title: "亚马逊商品链接",
        dataIndex: "amazon_url",
        key: "amazon_url",
        width: 160,
    },
    {
        title: "申报价值",
        dataIndex: "declareds",
        width: 130,
        key: "declareds",
        scopedSlots: { customRender: 'declareds' },

    },
    // {
    //     title: "材质",
    //     dataIndex: "material_cates",
    //     width: 130,
    //     key: "material_cates",
    //     scopedSlots: { customRender: 'materialCates' },
    // },
    {
        title: "品名中文名",
        dataIndex: "item",
        width: 130,
        key: "item",
        scopedSlots: { customRender: 'itemArr' },
    },
    {
        title: "产品图片",
        dataIndex: "photo",
        width: 130,
        key: "photo",
        scopedSlots: { customRender: 'photo' },
    },
    {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 120,
        scopedSlots: { customRender: 'action' },
    }

]
