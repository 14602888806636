<!--
 * @Author: your name
 * @Date: 2021-12-08 11:23:09
 * @LastEditTime: 2022-01-05 13:49:14
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/Product/curd/_edit.vue
-->
<template>
  <div class="p-form">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item
        label="产品标题"
        prop="zhName"
        :rules="validate({ name: 'value', msg: '请输入产品标题' })"
      >
        <a-input v-model="form.zhName" placeholder="产品标题"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="产品中文品名"
        prop="zhItemName"
        :rules="validate({ name: 'value', msg: '请输入产品中文品名' })"
      >
        <a-input v-model="form.zhItemName" placeholder="产品中文品名"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="产品英文品名"
        prop="enItemName"
        :rules="validate({ name: 'value', msg: '请输入产品英文品名' })"
      >
        <a-input v-model="form.enItemName" placeholder="产品英文品名"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="用途中文名"
        prop="zhPurpose"
        :rules="validate({ name: 'value', msg: '请输入用途中文名' })"
      >
        <a-input v-model="form.zhPurpose" placeholder="用途中文名"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="用途英文名"
        prop="enPurpose"
        :rules="validate({ name: 'value', msg: '请输入用途英文名' })"
      >
        <a-input v-model="form.enPurpose" placeholder="用途英文名"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="材质中文名"
        prop="zhMaterial"
        :rules="validate({ name: 'value', msg: '请输入材质中文名' })"
      >
        <a-input v-model="form.zhMaterial" placeholder="材质中文名"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="材质英文名"
        prop="enMaterial"
        :rules="validate({ name: 'value', msg: '请输入材质英文名' })"
      >
        <a-input v-model="form.enMaterial" placeholder="材质英文名"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="产品品牌"
        prop="brand"
        :rules="validate({ name: 'value', msg: '请输入产品品牌' })"
      >
        <a-input v-model="form.brand" placeholder="产品品牌"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="产品型号"
        prop="model"
        :rules="validate({ name: 'value', msg: '请输入产品型号' })"
      >
        <a-input v-model="form.model" placeholder="产品型号"> </a-input>
      </a-form-model-item>
      <a-form-model-item
        label="产品图片"
        prop="photo"
        :rules="validate({ name: 'value', msg: '请上传图片' })"
      >
        <a-upload
          name="image"
          list-type="picture-card"
          class="avatar-uploader"
          :headers="uploadHeaders"
          :show-upload-list="false"
          :action="action"
          :before-upload="beforeUpload"
          @change="handleChange"
        >
          <img class="pImg" v-if="imageUrl" :src="imageUrl" alt="avatar" />
          <div v-else>
            <a-icon :type="imgLoading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">上传图片</div>
          </div>
          <span>注：支持格式JPG,PNG</span>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item
        label="海关编码"
        prop="hsCode"
        :rules="validate({ name: 'value', msg: '请输入海关编码' })"
      >
        <a-input v-model="form.hsCode" placeholder="海关编码"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="申报价值"
        prop="declareds"
        :rules="
          validate({ name: 'value', type: 'array', msg: '请输入申报价值' })
        "
      >
        <a-input-group
          compact
          v-for="(item, index) in form.declareds"
          :key="index"
        >
          <a-select
            style="width: 40%"
            v-model="item.currencyId"
            placeholder="申报价值"
          >
            <a-select-option :value="it.id" v-for="it in cList" :key="it.id">
              {{ it.name }}
            </a-select-option>
          </a-select>
          <a-input-number
            style="width: 46%"
            :precision="2"
            v-model="item.declaredValue"
            placeholder="价值"
          >
          </a-input-number>
          <!-- <a-button v-else type="primary" icon="minus" @click="minus(form.declareds, index)"></a-button> -->
        </a-input-group>
        <a-button type="primary" @click="addCurrency(form.declareds)"
          >+新增申报价值</a-button
        >
      </a-form-model-item>
      <!-- <a-form-model-item label="标签" prop="categorys">
                <a-checkbox-group v-model="form.categorys" name="categorys" :options="categoryList" />
            </a-form-model-item> -->
      <!-- <a-form-model-item label="材质" prop="caterialCates">
                <a-checkbox-group v-model="form.caterialCates" name="mList" :options="mList" />
            </a-form-model-item> -->
      <!-- <a-form-model-item label="品名中文名" prop="itemId" :rules="validate({name: 'value', msg: '请选择品名'})"> -->
      <!-- <chooseProductName v-model="form.itemId"></chooseProductName> -->
      <!-- <a-select show-search v-model="form.itemId" placeholder="选择品名" style="width: 100%" :default-active-first-option="false" :show-arrow="true" :filter-option="false" :not-found-content="null" @search="searchItems" @change="handleItems">
                    <a-select-option v-for="p in pItems" :key="p.id" :value="p.id">
                        {{ p.zh_name + '-' + p.en_name}}
                    </a-select-option>
                </a-select>
            </a-form-model-item> -->
      <!-- <a-form-model-item label="重量kg" prop="weight">
                <a-input-number style="width: 100%" v-model="form.weight" placeholder="重量"></a-input-number>
            </a-form-model-item> -->
      <a-form-model-item label="亚马逊商品链接" prop="amazonUrl">
        <a-input
          style="width: 100%"
          v-model="form.amazonUrl"
          placeholder="亚马逊商品链接"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="亚马逊编码" prop="amazonAsin">
        <a-input
          style="width: 100%"
          v-model="form.amazonAsin"
          placeholder="亚马逊编码"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="产品分类" prop="categorys">
        <a-checkbox-group
          v-model="form.categorys"
          name="categorys"
          :options="categoryList"
        />
        <a-button type="primary" @click="openCat">分类管理</a-button>
        <ly-drawer
          :title="drawer.title"
          :visible.sync="drawer.show"
          :width="drawer.width"
          @cancel="handlerCancel"
          @ok="handlerOk"
        >
          <category v-if="drawer.show"></category>
        </ly-drawer>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  productCategory,
  materialList,
  currencyList,
  productItems,
} from "@/api/comm";
import { productAdd, productInfo, productEdit } from "@/api/Product";
// import imgUrl from "@/utils/imgUrl"
import store from "@/store";
import category from "./category.vue";
import { hump } from "@/utils/prarms";
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  props: {
    type: {
      type: String,
      default: "add",
    },
    productId: {
      type: [String, Number],
      default: "",
    },
  },
  components: {
    category,
  },
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      categoryList: [],
      mList: [],
      cList: [],

      pItems: [],
      drawer: {
        title: "",
        width: "700px",
        show: false,
      },
      action: process.env.VUE_APP_API_URL + "/file/upload/image",
      uploadHeaders: {
        "device-type": "web",
        "ansuex-user-token": store.getters.getToken,
      },
      imgLoading: false,
      imageUrl: "",
      form: {
        zhName: "",
        zhPurpose: "",
        enPurpose: "",
        enItemName: "",
        zhItemName: "",
        zhMaterial: "",
        enMaterial: "",
        brand: "",
        model: "",
        hsCode: "",
        categorys: [],
        itemId: "",
        weight: "",
        amazonUrl: "",
        amazonAsin: "",
        photo: "",
        declareds: [
          {
            currencyId: "",
            declaredValue: "",
          },
        ],
      },
    };
  },
  created() {
    this.productCategory();
    this.materialList();
    this.currencyList();
    this.searchItems("");
    this.getData();
  },
  methods: {
    getData() {
      if (this.type === "edit" && this.productId) {
        productInfo({ productId: this.productId }).then((res) => {
          const obj = res.data;
          this.form = Object.assign(this.form, hump(obj));
          this.imageUrl = this.form.photo.includes("http")
            ? this.form.photo
            : process.env.VUE_APP_CDN + this.form.photo;
          this.form.productId = this.productId;
          this.form.declareds = obj.declareds || [];
          this.form.enItemName = obj.itemEnName;
          this.form.zhItemName = obj.itemZhName;
          const categorys = this.form.categorys || [];
          const arr = [];
          categorys.forEach((ele) => {
            arr.push(ele.id);
          });
          this.form.categorys = arr;
        });
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("请上传图片!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片不能超过2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.imgLoading = true;
        return;
      }
      if (info.file.status === "done") {
        this.form.photo = info.file.response.data.path;
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.imgLoading = false;
        });
      }
    },
    searchItems(val) {
      let obj = {
        zhName: val,
        limit: 1000,
        page: 1,
      };
      productItems(obj).then((res) => {
        this.pItems = res.data.list;
      });
    },
    handleItems(val) {
      this.form.itemId = val;
    },
    // 分类列表
    productCategory() {
      productCategory().then((res) => {
        const list = res.data;
        const arr = [];
        list.forEach((ele) => {
          let obj = {
            label: ele.name,
            value: ele.id,
          };
          arr.push(obj);
        });
        this.categoryList = arr;
      });
    },
    // 材质列表
    materialList() {
      materialList().then((res) => {
        const list = res.data;
        const arr = [];
        list.forEach((ele) => {
          let obj = {
            label: ele.name,
            value: ele.id,
          };
          arr.push(obj);
        });
        this.mList = arr;
      });
    },
    // 币种列
    currencyList() {
      currencyList().then((res) => {
        this.cList = res.data;
      });
    },
    // 添加币种
    addCurrency() {
      console.log(this.form.declareds);
      this.form.declareds.push({
        currencyId: "",
        declaredValue: "",
      });
    },
    //添加分类
    openCat() {
      this.drawer.width = "700px";
      this.drawer.title = "分类管理";
      this.drawer.show = true;
    },
    handlerCancel() {
      this.drawer.show = false;
      this.productCategory();
    },
    handlerOk() {
      this.drawer.show = false;
      this.productCategory();
    },
    // 减少币种
    // minus(arr, n) {
    //     arr.splice(n, 1);
    // },
    // 提交
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.form.weight = 0;
          this.form.itemId = 0;
          if (this.type === "add") {
            productAdd(this.form).then(() => {
              this.$message.success("产品添加成功");
              this.$emit("success");
            });
          } else {
            productEdit(this.form).then(() => {
              this.$message.success("产品编辑成功");
              this.$emit("success");
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.p-form {
  width: 400px;
  padding-bottom: 20px;
}

.pImg {
  width: 300px;
}
</style>
