<!--
 * @Author: your name
 * @Date: 2021-12-08 17:13:43
 * @LastEditTime: 2021-12-08 17:46:29
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/Product/curd/category.vue
-->
<template>
    <div class="p-form">
        <div align="right">
            <a-button type="primary" icon="plus" @click="add">新增分类</a-button>
        </div>
        <a-table size="small" style="margin-top: 20px" bordered :columns="columns" :data-source="tableData" :pagination="false" :rowKey="(record,index) => index">
            <template slot="action" slot-scope="record">
                <a class="btn-a" @click="edit(record)">编辑</a>
                <a-popconfirm ok-text="确定" cancel-text="取消" @confirm="handlerDel(record)">
                    <template slot="title">
                        <span>您确认删除分类名称"{{ record.name }}"嘛？</span>
                    </template>
                    <a class="btn-a" style="color: red">删除</a>
                </a-popconfirm>
            </template>
        </a-table>
        <a-modal :title="modal.title" :visible="modal.visible" :width="modal.width" :confirm-loading="confirmLoading"  :maskClosable="false" @ok="handleOk" @cancel="handleCancel">
            <a-form-model ref="cteForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="分类名称" prop="name" :rules="validate({ name: 'value', msg: '请输入分类名称' })">
                    <a-input v-model="form.name" allowClear placeholder="分类名称"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
    import { productCategory } from "@/api/comm";
    import { pcAdd, pcDel, pcEdit } from "@/api/Product"
    export default {
        data() {
            return {
                modal: {
                    title: "",
                    visible: false,
                    width: "460px"
                },
                visible: false,
                tableData: [],
                type: "add",
                labelCol: { span: 6 },
                wrapperCol: { span: 14 },
                confirmLoading: false,
                form: {
                    productCategoryId: '',
                    name: "",
                },
                columns: [
                    {
                        title: ' 序号 ',
                        width: 70,
                        customRender: (text, record, index) =>
                            `${index + 1}`
                    },
                    {
                        title: "分类名称",
                        dataIndex: "name",
                        key: "name",
                    },
                    {
                        title: "操作",
                        key: "action",
                        // fixed: "right",
                        scopedSlots: { customRender: "action" },
                    },
                ],
            };
        },
        created() {
            this.productCategory();
        },
        methods: {
            productCategory() {
                productCategory().then((res) => {
                    const list = res.data;
                    this.tableData = list;

                });
            },

            add() {
                this.type = "add"
                this.modal.visible = true;
                this.modal.title = "新增产品分类"
                this.modal.width = "460px"
                this.form.name = ""
            },
            edit(val) {
                this.type = "edit"
                this.modal.visible = true;
                this.modal.title = "修改产品分类"
                this.modal.width = "460px"
                this.form.name = val.name
                this.form.productCategoryId = Number(val.id.replace(/[^\d]/g, ''))
            },
            handleCancel() {
                this.modal.visible = false;
            },
            handleOk() {
                this.$refs.cteForm.validate((valid) => {
                    if (valid) {
                        const apiUrl = this.type == "add" ? pcAdd : pcEdit
                        const msg = this.type == "add" ? '分类添加成功' : "分类编辑成功"
                        apiUrl(this.form).then(() => {
                            this.modal.visible = false;
                            this.$message.success(msg);
                            this.productCategory();
                        })
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                })
            },
            handlerDel(row) {
                pcDel({ productCategoryId: Number(row.id.replace(/[^\d]/g, '')) }).then(() => {
                    this.$message.success("分类删除成功");
                    this.productCategory();
                })
            },
        },
    };
</script>
<style lang="less" scoped>
</style>