<!--
 * @Author: your name
 * @Date: 2021-12-08 09:53:03
 * @LastEditTime: 2021-12-29 09:55:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/Product/index.vue
-->
<template>
  <div class="page-container">
    <a-form-model layout="inline" :model="form">
      <a-form-model-item label="商品名称">
        <a-input v-model="form.goodsName" placeholder="商品名称" />
      </a-form-model-item>
      <a-form-model-item label="产品分类">
        <a-select
          v-model="form.categoryIds"
          mode="multiple"
          placeholder="产品分类"
          style="width: 180px"
        >
          <a-select-option
            v-for="item in categoryList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-button-group>
          <a-button type="primary" @click="search"> 查询 </a-button>
          <a-button @click="reset"> 重置 </a-button>
        </a-button-group>
      </a-form-model-item>
    </a-form-model>
    <div align="right">
      <a-button-group>
        <!-- <a-button @click="openCat">管理标签</a-button> -->
        <a-button type="primary" @click="add">添加</a-button>
      </a-button-group>
    </div>
    <a-table
      size="small"
      bordered
      :columns="columns"
      :data-source="tableData"
      style="margin-top: 20px"
      :scroll="{ x: 1400 }"
      :rowKey="(record, index) => index"
      :pagination="pagination"
      @change="handlerPage"
    >
      <template slot="declareds" slot-scope="text">
        <span>{{ text.length > 0 ? text[0].currency_name || "-" : "-" }}</span>
        <a-popover placement="bottom">
          <template slot="content">
            <p v-for="(ix, n) in text" :key="n">
              <span>{{ ix.currency_name }}</span
              ><span>({{ ix.declared_value }})</span>
            </p>
          </template>
          <template slot="title">
            <span>货币</span>
          </template>
          <a class="btn-a" style="margin-left: 10px">查看</a>
        </a-popover>
      </template>
      <template slot="photo" slot-scope="record">
        <a-popover placement="bottom">
          <template slot="content">
            <img
              style="width: 200px"
              :src="
                record.photo.includes('http')
                  ? record.photo
                  : process.env.VUE_APP_CDN + record.photo
              "
              v-if="record.photo"
            />
          </template>
          <template slot="title">
            <span>图片</span>
          </template>
          <a class="btn-a" style="margin-left: 10px">查看</a>
        </a-popover>
      </template>
      <!-- <template slot="materialCates" slot-scope="text">
                <span v-if="text.length > 0">{{text[0].name || "-"}}</span>
                <a-popover placement="bottom" v-if="text.length > 0">
                    <template slot="content">
                        <p v-for="(ix,n) in text" :key="n"><span>{{ix.name}}</span></p>
                    </template>
                    <template slot="title">
                        <span>材质</span>
                    </template>
                    <a class="btn-a" style="margin-left: 10px">查看</a>
                </a-popover>
                <span v-if="text.length === 0">-</span>
            </template> -->
      <template slot="itemArr" slot-scope="text">
        <span>{{ text.zh_name || "-" }}</span>
        <a-popover placement="bottom">
          <template slot="content">
            <p>
              <span>{{ text.zh_name }}</span>
            </p>
          </template>
          <template slot="title">
            <span>其他</span>
          </template>
          <a class="btn-a" style="margin-left: 10px">查看</a>
        </a-popover>
      </template>
      <template slot="action" slot-scope="record">
        <a class="btn-a" @click="handlerEdit(record)">编辑</a>
        <a-popconfirm
          ok-text="确定"
          cancel-text="取消"
          @confirm="handlerDel(record)"
        >
          <template slot="title">
            <span>确定要删除嘛？</span>
          </template>
          <a class="btn-a" style="color: red">删除</a>
        </a-popconfirm>
      </template>
    </a-table>
    <a-drawer
      :title="modal.title"
      :visible="modal.visible"
      :width="modal.width"
      @close="handleCancel"
      :maskClosable="false"
    >
      <p-edit
        v-if="modal.visible && modal.index === 1"
        :type="pdType"
        :productId="productId"
        ref="pd"
        @success="handlerSuc"
      ></p-edit>
      <!-- <category v-if="modal.visible && modal.index === 2"></category> -->
      <div class="drawerFooter" v-if="[1].includes(modal.index)">
        <a-button-group>
          <a-button @click="handleCancel"> 取消 </a-button>
          <a-button type="primary" @click="handlerSubmit"> 确定 </a-button>
        </a-button-group>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { productList, productCategory } from "@/api/comm";
import { productDel } from "@/api/Product";
import columns from "./curd/columns";
import p_edit from "./curd/_edit.vue";
// import category from "./curd/category.vue"
export default {
  name: "Product",
  components: {
    "p-edit": p_edit,
    // category
  },
  data() {
    return {
      pdType: "add",
      productId: "",
      categoryList: [],
      form: {
        goodsName: "",
        categoryIds: [],
        limit: 20,
        page: 1,
      },
      pagination: {
        current: 1,
        total: 0,
        pageSize: 20,
        showTotal: (total) => `共 ${total} 条`,
        showSizeChanger: true,
        showQuickJumper: true,
      },
      modal: {
        title: "",
        visible: false,
        width: "700px",
        index: 0,
      },
      tableData: [],
      columns: columns,
    };
  },
  created() {
    this.getData();
    this.productCategory();
  },
  methods: {
    // 分类列表
    productCategory() {
      productCategory().then((res) => {
        const list = res.data;
        this.categoryList = list;
      });
    },
    // 获取列表数据
    getData() {
      productList(this.form).then((res) => {
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
      });
    },
    // 切换分页
    handlerPage(p) {
      this.form.page = p.current;
      this.form.limit = p.pageSize;
      this.pagination.current = p.current;
      this.pagination.pageSize = p.pageSize;
      this.getData();
    },
    // 查询
    search() {
      this.form.page = 1;
      this.pagination.current = 1;
      this.getData();
    },
    //重置
    reset() {
      this.form.goodsName = "";
      this.getData();
    },
    handleCancel() {
      this.modal.visible = false;
    },
    // 添加
    add() {
      this.pdType = "add";
      this.modal.title = "产品分类";
      this.modal.index = 1;
      this.modal.visible = true;
    },
    // 编辑
    handlerEdit(row) {
      this.productId = row.id;
      this.modal.index = 1;
      this.pdType = "edit";
      this.modal.title = "编辑商品";
      this.modal.visible = true;
    },
    // 删除
    handlerDel(row) {
      productDel({ productId: row.id }).then(() => {
        this.$message.success("产品删除成功");
        this.getData();
      });
    },
    handlerSubmit() {
      this.$refs.pd.submit();
    },
    // 产品添加成功
    handlerSuc() {
      this.modal.visible = false;
      this.getData();
    },
    // 管理分类
    openCat() {
      this.modal.index = 2;
      this.modal.title = "管理分类";
      this.modal.visible = true;
    },
  },
};
</script>

<style lang="less" scoped></style>
